import React from "react";
import { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { convertTimeStampToDate } from "../../helperFunc/convertDate";
import { convert } from "../../helperFunc/graphDate";
import DownloadMenu from "./DownloadMenu";
import ApexCharts from "apexcharts";


const EnergyGraph = (props) => {
  const [data, setData] = useState([]);
  const [date, setDate] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const [display, setDisplay] = useState(false);
  useEffect(() => {
    setTimeout(() => setDisplay(true), 1);
  }, []);

  useEffect(() => {
    const dat = [];
    const dates = [];
    const csvDat = [];

    props.data.forEach((data) => {
      let foo = data.Timestamp.toString();
      let date = convertTimeStampToDate(foo);

      let units = (parseFloat(data[props.nodeSelect]?.Mean_Eact) || 0).toFixed(
        2
      );

      dates.push(date);
      dat.push({
        x: convert(date, props.showTime),
        y: units,
      });
      csvDat.push([convert(date, props.showTime), units].join(","));

      // i++;
    });
    dat.reverse();
    setData(dat);
    setCsvData(csvDat);
    setDate(dates);
  }, [props.data, props.nodeSelect, props.showTime]);


  const chartDetails = {
    options: {
      chart: {
        id: "energyConsumedGraph",
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },

      xaxis: {
        tickAmount: 4,
        tickPlacement: "on",
        labels: {
          show: true,
          rotate: 0,
        },
        title: {
          text: "Time",
        },
      },
      yaxis: {
        title: {
          text: "Units (kWh)",
        },
      },
      stroke: {
        colors: ["transparent"],
        // width: 10,
      },
      // "asiasioas"
      plotOptions: {
        bar: {
          columnWidth: "40%",
          // barWidth: "50%",
          // barHeight: "80%",
        },
      },
      responsive: [
        {
          breakpoint: 900,
          options: {
            xaxis: {
              labels: {
                show: false,
              },
            },
          },
        },
      ],
    },
    series: [
      {
        name: "Units",
        data: data,
        color: "#298F9D",
      },
    ],
  };

  if (!display) {
    return <></>;
  }

  const downloadImg = () => {
    ApexCharts.exec("energyConsumedGraph", "dataURI", { scale: 3 }).then(
      ({ imgURI }) => {
        const anchor = document.createElement("a");
        anchor.href = imgURI;
        anchor.download = "EnergyGraph.png";
        anchor.click();
      }
    );
  };

  const CsvDownload = () => {
    const header = "Date,Units";
    let data = [header, ...csvData];

    let csv = data.join("\n");

    const blob = new Blob([csv], { type: "text/csv" });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");

    a.setAttribute("href", url);

    a.setAttribute("download", "EnergyConsumptionData.csv");
    a.click();
  };

  return (
    <div className="relative">
      <Chart
        options={chartDetails.options}
        series={chartDetails.series}
        type="bar"
        height={props.graphHeight}
      />
      <DownloadMenu downloadImg={downloadImg} downloadCsv={CsvDownload} />
    </div>
  );
};

export default EnergyGraph;
